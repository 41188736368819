import React, {useEffect, useState} from "react";
import randy from "randy";
import Decimal from "decimal.js";
import {Progress, Spin, Alert, Col, Row, Form, Slider, InputNumber} from "antd";
import styled, {keyframes} from "styled-components";
import { useWallet, UseWalletProvider } from 'use-wallet'
import Web3 from "web3";
import Web3Modal from "web3modal";
import { LoadingOutlined } from '@ant-design/icons';

import Button from "./Button";
import Text from "./Text";
import {fillField} from "../lib/helpers";

import {PeaceAliensABI} from "../lib/abis";
import PeaceAliensGIF from "../assets/PeaceAliens.gif";

const options = {
  network: "mainnet",
  chainId: 1,
  address: "0x06e75806cc1d510174aed109857fd26f9c8e6186",
  friendAddress: "0xb9e971c00b1257d0f6d67eff37f4a46e1ab6bf24",
};

/*const options = {
  network: "rinkeby",
  chainId: 4,
  address: "0xcb05BA3F928Fc58F1813Dab3D5B61B2Eb0da974d",
  friendAddress: "0x2D2BC79762FE555b4F9FdDA5402e1f0dEd47398f",
  };*/


const providerOptions = {
  /* See Provider Options Section */
};

export default () => {
  const formRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(444);
  const [connector, setConnector] = useState({});
  const [result, setResult] = useState({});
  let web3 = null;

  const web3Modal = new Web3Modal({
    network: options.network, // optional
    chainId: options.chainId,
    cacheProvider: false, // optional
    providerOptions // required
  });

  const resetApp = async () => {
    const { web3 } = connector;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await web3Modal.clearCachedProvider();
    setConnector({});
  };

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => resetApp());
    provider.on("accountsChanged", async (accounts) => {
      await setConnector({
        ...connector,
        address: accounts[0],
      });
    });
    provider.on("chainChanged", async (chainId) => {
      const networkId = await web3.eth.net.getId();
      await setConnector({
        ...connector,
        chainId,
        networkId,
      });
    });

    provider.on("networkChanged", async (networkId) => {
      const chainId = await web3.eth.chainId();
      await setConnector({
        ...connector,
        chainId,
        networkId,
      });
    });
  };

  function initWeb3(provider) {
    const web3: any = new Web3(provider);
    web3.eth.extend({
      methods: [
        {
          name: "chainId",
          call: "eth_chainId",
          outputFormatter: web3.utils.hexToNumber
        }
      ]
    });

    return web3;
  }

  const onConnect = async () => {
    const provider = await web3Modal.connect();
    await subscribeProvider(provider);
    web3 = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.chainId();
    if (chainId !== options.chainId) {
      return setConnector({
        error: "Please connect your wallet (Metamask) to Ethereum Mainnet and try again.",
      });
    }
    await setConnector({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId,
    });
    const contract = await new web3.eth.Contract(PeaceAliensABI, options.address);
    const n = await contract.methods.freePeaceAliensAvailable().call();
    setAvailable(n);

    setInterval(async () => {
      const contract = await new web3.eth.Contract(PeaceAliensABI, options.address);
      const n = await contract.methods.freePeaceAliensAvailable().call();
      setAvailable(n);
    }, 20 * 1000);
  };


  useEffect(() => {
    if (web3Modal.cachedProvider) {
      onConnect();
    }
  }, []);

  const mint = async () => {
    setResult({});
    const {
      current: {
        validateFields,
      },
    } = formRef;
    const result = await validateFields();

    try {
      setLoading(true);
      const {
        web3,
        address,
      } = connector;
      const contract = await new web3.eth.Contract(PeaceAliensABI, options.address);
      const gasLimit = await contract.methods.friendMint(options.friendAddress).estimateGas({
        from: address,
      });
      const gp = await web3.eth.getGasPrice();
      const gasPrice = Math.min(gp, randy.choice([107694041205, 117694041205, gp]));
      const coolNumber = await contract.methods.friendMint(options.friendAddress).send({
        from: address,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        gasLimit: gasLimit + randy.choice([500, 1000, 2000, 3000, 4000]),
        gasPrice,
      })
      .on('transactionHash', (transactionHash) => {
        setResult({
          ...result,
          status: "confirming",
          transactionHash,
        });
      });
      setResult(coolNumber);
    } catch (e) {
      setResult({
        ...result,
        error: e,
      });
      console.log(e);
    }
    setLoading(false);
  };

  const {
    connected,
    address,
    error,
  } = connector;

  const {
    transactionHash,
    status,
    error: resultError,
  } = result;

  if (error) {
    return (
      <FormWrapper>
        <Alert type="error" message={error} />
      </FormWrapper>
    );
  }
  if (!connected) {
    return (
      <div className="mt20">
        <Text size="xxs" className="mb20">Please click the connect button to connect with your wallet.</Text>
        <Button htmlType="button" size="m" className="w250 glow off" onClick={onConnect}>
          Connect
        </Button>
      </div>
    );
  }

  return (
    <Row className="ph15 mt20">
      <Col xs={0} sm={4} />
      <Col xs={24} sm={5}>
        <GifWrapper>
          <Gif className="fill" src={PeaceAliensGIF} />
        </GifWrapper>
      </Col>
      <Col xs={0} sm={1} />
      <Col xs={24} sm={9}>
        <Form
          ref={formRef}
          onFinish={mint}
          initialValues={{
            amount: 1,
          }}
        >
          <FormWrapper>
            <div className="text-left mb20">
              {resultError && (
                <Alert
                  type="error"
                  message={resultError.code === -32000 ? (
                    "Not enough balance in your wallet to pay for the price + gas fee. Please deposit more ETH to your wallet."
                  ) : (
                    resultError.message
                  )}
                />
              )}

              {status === true && (
                <Alert
                  type="success"
                  message={(
                    <div>
                      <Text size="xxs" color="black" bold className="mb10">Thank you for minting Peace Aliens NFT!</Text>
                      <Text size="xxs" color="black">
                        txn hash: <a target="_blank" href={`https://etherscan.io/tx/${transactionHash}`}>{transactionHash}</a>
                      </Text>
                    </div>
                  )}
                />
              )}
              {loading && (
                <Alert
                  type="warning"
                  message={(
                    <div>
                      <Text color="black" bold size="xxs" className="mb10">
                        <LoadingOutlined style={{ fontSize: 10}} spin />
                        <div className="inline-block ml5">CONFIRMING TRANSACTION</div>
                      </Text>
                      <Text color="black" size="xxs">
                        {status === "confirming" ? (
                          <div>
                            <div>txn hash: <a target="_blank" href={`https://etherscan.io/tx/${transactionHash}`}>{transactionHash}</a></div>
                          </div>
                        ) : (
                          <div>Please confirm the transaction in your wallet (metamask).</div>
                        )}
                      </Text>
                    </div>
                  )}
                />
              )}
            </div>
            <div className="text-left mb20">
              <div className="flex-h">
                <div className="flex-1"><Text size="xxs" className="mb5">CONNECTED WALLET</Text></div>
                <div><Disconnect onClick={resetApp}>LOGOUT</Disconnect></div>
              </div>
              <Text size="xs" color="green">
                <a href={`https://etherscan.io/address/${address}`} target="_blank" rel="noreferrer noopener">
                  {address}
                </a>
              </Text>
            </div>

            <Row gutter="20" className="mb20">
              <Col xs={24}>
                <Text size="xxs" bold>FREE MINT</Text>
                <Text size="xxs">(excluding gas fee)</Text>
              </Col>
            </Row>
            <Button className="w250 glow active-glow" loading={loading} size="m">Friend Mint</Button>
          </FormWrapper>
        </Form>
      </Col>
      {available < 400 && (
        <>
          <Col xs={0} sm={4} />
          <Col xs={0} sm={4} />
          <Col xs={24} sm={15}>
            <div className="mt30 text-left">
              <Text size="xxs" className="mb5">FRIEND MINTED</Text>
              <Progress size="small" percent={((444 - available)/444) * 100} showInfo={false} />
              <Text size="xs">{444 - available}/444</Text>
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};

const Disconnect = styled.div`
  color: #48c248 !important;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

`;

const FormWrapper = styled.div`
  width: 330px;
  margin: auto;
  .ant-form-item-label {
    text-align: left;
    label {
      color: white !important;
      &:before {
        content: "" !important;
      }
    }
    .ant-form-item-required {

    }
  }

`;
const shine = keyframes`
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
`;
const Gif = styled.img`
  opacity: 1;
  border-radius: 50%;
`;
const GifWrapper = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 50%;
  
  &:after {
    animation: ${shine} 0s ease-in-out infinite;
    animation-fill-mode: forwards;  
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    
    background: rgba(255, 255, 255, 0.33);
    background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0.33) 0%,
      rgba(255, 255, 255, 0.33) 77%,
      rgba(255, 255, 255, 0.7) 92%,
      rgba(255, 255, 255, 0.0) 100%
    );
  }
`;
