import styled from "styled-components"

export default () => {
  return (
    <Header className="flex-h flex-center">
    </Header>
  );
}

const Header = styled.div`
  background: ${props => props.theme.background};
`;