import React from "react";
import {Row, Col} from "antd";
import { GreenBox } from '../components/Layout';

import Text from "../components/Text";
import ethImg from "../assets/ethereum.png";
import ipfsImg from "../assets/ipfs.png";

export default () => (
  <GreenBox className="mt50 pv30 ph50 text-center">
    <Text as="h2" size="h2" color="green">Tech Stack</Text>
    <Row gutter="50" className="mt20">
      <Col xs={0} sm={8} />
      <Col xs={12} sm={4}>
        <img src={ethImg} alt="ethereum" className="fill mb10" />
        <Text color="gray" size="xxs" light>ERC721</Text>
      </Col>
      <Col xs={12} sm={4}>
        <img src={ipfsImg} alt="ipfs" className="fill" />
      </Col>
      <Col xs={0} sm={8} />
    </Row>
  </GreenBox>
);
