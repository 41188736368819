import Countdown from 'react-countdown';
import styled from "styled-components";
import {media} from "../lib/helpers";

export const CountDownWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px 0;
  margin: 0 0;
  background: #5b40bb;
`;

export const CountDownWrapperBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px 0;
  margin: 0 2px;
  border-radius: 0 0 9px 9px;
  background: #111;
`;

export default ({
  dateUnix,
  closing,
}) => (
  <Wrapper>
    <Countdown
      date={dateUnix * 1000}
      renderer={({
        days,
        hours,
        minutes,
        seconds,
        completed,
      }) => {
        if (completed) {
          // Render a completed state
          return false;
        }
        const timer = [closing ? "" : ""];
        if (days) {
          timer.push(`${days} Days`);
        }
        if (hours) {
          timer.push(`${hours} Hours`);
        }
        if (minutes) {
          timer.push(`${minutes} Minutes`);
        }
        timer.push(`${seconds} Seconds`);
        return (timer.join(" "));
      }}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  color: white;
  font-family: 'Cute Font',sans-serif;
  font-size: 40px;
  ${media.xs`
    font-size: 26px;
    font-family: 'Cute Font',sans-serif;
  `}
`;

