export default {
  background: "#031316",
  headerHeight: "54px",
  maxWidth: "940px",
  colors: {
    white: "white",
    green: "#48c248",
    alien: {
      gradient: "linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #237923, #154d15)",
      background: "#48c248",
    },
  }
}
