import React from 'react';
import dayjs from "dayjs";
import {Row, Col} from "antd";
import styled from "styled-components";
import { SocialIcon } from 'react-social-icons';

//import {history} from "../lib/configureStore";
import { Content, Wrapper, GreenBox } from '../components/Layout';
import Text from "../components/Text";
import Logo from "../components/Logo";
import Roadmap from "../components/Roadmap";
import Rarity from "../components/Rarity";
import TechStack from "../components/TechStack";

import gfImage from "../assets/no-clothes/78.png";
import Marquee from "../components/Marquee";
import {media} from "../lib/helpers";
import CountConnectForm from "../components/CountConnectForm";

import be from "../assets/team/3.png";
import kp from "../assets/team/24.png";
import bt from "../assets/team/64.png";

import OpenSeaImg from "../assets/opensea.svg";
import EtherscanImg from "../assets/etherscan.webp";

const mintDate = 1634997600;
const formatted = dayjs(mintDate * 1000).format("MMMM DD, YYYY HH:mm:ss");

export default ({
  routes,
}) => (
  <Content>
    <Wrapper className="mt30">
      <Row>
        <Col xs={24} sm={16}>
          <div className="flex-v flex-1 flex-center fill-h">
            <LogoWrapper className="flex-1 flex-v"><Logo /></LogoWrapper>
            <Text size="sub" className="flex-1" bold>
              Peace Aliens is a collection of 8877 handcrafted and randomly generated collectible NFTs. Each of Peace Aliens has its unique traits, and some are rarer than others.
            </Text>
          </div>
        </Col>
        <Col xs={24} sm={8}>
          <div className="text-center">
            <Mascott src={gfImage} />
          </div>
        </Col>
      </Row>
    </Wrapper>
    <GreenBox className="text-center pv30">
      <Text as="h2" size="h2" color="green">MINT</Text>
      <div className="pb20">
        <CountConnectForm mintDate={mintDate} formatted={formatted} />
      </div>
    </GreenBox>

    <GreenBox className="mt50 mb50">
      <div>
        <Text size="m" className="pt50 ph50">
          <Text as="h2" size="h2" color="green">STORY</Text>
          <div className="mt20">
            In 2050, aliens finally appeared in our planet earth. It turns out, they are cute and highly intelligent friendly creatures that come in peace.
            They possess special technologies capable of solving our environmental problems.
          </div>
        </Text>
      </div>
      <div className="mt50">
        <Marquee />
      </div>
    </GreenBox>
    <Wrapper className="mt40">
      <Row>
        <Col xs={0} sm={3} />
        <Col xs={24} sm={6}>
          <Social className="flex-h mt10" target="_blank" href="https://twitter.com/PeaceAliensNFT">
            <div><SocialIcon network="twitter" /></div>
            <div className="flex-center flex-1 flex-v ml10">
              <Text size="s" color="black" bold className="lh1">
                Follow us on
                <br />
                Twitter
              </Text>
            </div>
          </Social>
        </Col>
        <Col xs={24} sm={6}>
          <Social className="flex-h mt10" target="_blank" href="https://discord.gg/ehD3x4mE66">
            <div><SocialIcon network="discord" /></div>
            <div className="flex-center flex-1 flex-v ml10">
              <Text size="s" color="black" bold className="lh1">
                Join us on
                <br />
                Discord
              </Text>
            </div>
          </Social>
        </Col>
        <Col xs={24} sm={6}>
          <Social className="flex-h mt10" target="_blank" href="https://opensea.io/collection/peacealiens">
            <div><img src={OpenSeaImg} className="w50" alt="OpenSea" /></div>
            <div className="flex-center flex-1 flex-v ml10">
              <Text size="s" color="black" bold className="lh1">
                OpenSea
                <br />
                Marketplace
              </Text>
            </div>
          </Social>
        </Col>
        {/*<Col xs={24} sm={6}>
          <Social className="flex-h mt10" target="_blank" href="https://etherscan.io/address/0x06e75806cc1d510174aed109857fd26f9c8e6186">
            <div><img src={EtherscanImg} className="w50" alt="Etherscan" /></div>
            <div className="flex-center flex-1 flex-v ml10">
              <Text size="s" color="black" bold className="lh1">
                Etherscan
                <br />
                Explorer
              </Text>
            </div>
          </Social>
        </Col>*/}
        <Col xs={0} sm={0} />
      </Row>
    </Wrapper>

    <GreenBox className="mt50 pv30 ph50">
      <Roadmap />
    </GreenBox>

    <Rarity />
    <TechStack />


    <GreenBox className="mt50 mb50 text-center pv30 ph50">
      <Text as="h2" size="h2" color="green">TEAM</Text>
      <div className="text-left mb50">
        <Text size="s">
          We believe in transparency. We will reveal our identities when Peace Aliens reached 100%. 
          If the community prefer us to be anonymous, then we can also keep it that way.
          <br /> <br />
          We are a group of passionate people who really seek for further development of Peace Aliens. 
          <br /> <br />
          If you have any questions, please feel free to contact us on discord, or twitter.
        </Text>
      </div>
      <Row>
        <Col xs={0} sm={6} />
        <Col xs={24} sm={4} className="mb20">
          <TeamImg src={bt} />
          <Text szie="l" bold>BRYTE</Text>
          <Text szie="m">Developer / Marketer</Text>
        </Col>
        <Col xs={24} sm={4} className="mb20">
          <TeamImg src={kp} />
          <Text szie="l" bold>KRISPA</Text>
          <Text szie="m">Artist</Text>
        </Col>
        <Col xs={24} sm={4} className="mb20">
          <TeamImg src={be} />
          <Text szie="l" bold>BATAE</Text>
          <Text szie="m">Concept</Text>
        </Col>
        <Col xs={0} sm={6} />
      </Row>
    </GreenBox>

    <div className="text-center flex-h flex-center mb30">
      <a className="mh10 inline-block" target="_blank" href="https://twitter.com/PeaceAliensNFT" rel="noreferrer">
        <SocialIcon network="twitter" />
      </a>
      <a className="mh10 inline-block" target="_blank" href="https://discord.gg/ehD3x4mE66" rel="noreferrer">
        <SocialIcon network="discord" />
      </a>
      <a className="mh10 inline-block" target="_blank" href="https://opensea.io/collection/peacealiens" rel="noreferrer">
        <img src={OpenSeaImg} className="w50" alt="OpenSea" />
      </a>
      {/*<a className="mh10 inline-block" target="_blank" href="https://etherscan.io/address/0x06e75806cc1d510174aed109857fd26f9c8e6186" rel="noreferrer">
        <img src={EtherscanImg} className="w50" alt="Etherscan" />
        </a>*/}
    </div>
  </Content>
);

const Mascott = styled.img`
  max-width: 100%;
  ${media.xs`
    max-width: 200px;
  `}

`;

const LogoWrapper = styled.div`
  align-items: flex-end;
  justify-content: flex-end;
  ${media.xs`
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
  `}
`;

const Social = styled.a`
  border-radius: 10px;
  background: white;
  padding: 10px;
  display: inline-block;
  margin: auto;
  width: 190px;
`;

const TeamImg = styled.img`
  width: 120px;
  margin-bottom: 10px;
`;
