import { ConfigProvider } from "antd";
import {Switch} from "react-router-dom";
import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers'

import routes from "../routes";
import { RouteWithSubRoutes } from "../lib/helpers";


function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

export default () => {
  return (
    <Web3ReactProvider
      getLibrary={getLibrary}
    >
      <ConfigProvider>
        {routes.map((route, i) => (
          <Switch key={i}>
            <RouteWithSubRoutes
              key={i}
              {...route}
            />
          </Switch>
        ))}
      </ConfigProvider>
    </Web3ReactProvider>
  );
}
