import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";

import Countdown from "../components/Countdown";
import FriendForm from "../components/FriendForm";

export default ({
  mintDate,
  formatted,
}) => {
  const [diff, setDiff] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = mintDate - dayjs().unix();
      setDiff(diff);
      if (diff <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }, [mintDate]);

  if (diff <= 0) {
    return <FriendForm />;
  } else {
    return <Countdown dateUnix={mintDate} />;
  }
};
