import React from "react";
import {Row, Col} from "antd";
import { GreenBox } from '../components/Layout';

import Text from "../components/Text";

export default () => (
  <GreenBox className="mt50 pv30 ph50 text-center">
    <Text as="h2" size="h2" color="green">Rarity</Text>
    <Row gutter="50" className="text-left">
      <Col xs={24} sm={12} className="mt20">
        <Text size="m" bold color="green">8 levels of rarity for each attribute:</Text>
        <Text size="xs" bold className="mt10">
          <Row>
            <Col xs={12}>
              1. normal 
              <br />
              ⭐
              <br />
              2. high normal 
              <br />
              ⭐🌟
              <br />
              3. rare 
              <br />
              ⭐🌟⭐
              <br />
              4. high rare 
              <br />
              ⭐🌟⭐🌟
              <br />
            </Col>
            <Col xs={12}>
              5. super rare
              <br />
              ⭐🌟⭐🌟⭐ 
              <br />
              6. double super rare
              <br />
              ⭐🌟⭐🌟⭐🌟 
              <br />
              7. ultra rare
              <br />
              ⭐🌟⭐🌟⭐🌟⭐
              <br />
              8. legendary
              <br />
              ⭐🌟⭐🌟⭐🌟⭐🌟 
              <br />
            </Col>
          </Row>
        </Text>
      </Col>
      <Col xs={24} sm={12} className="mt20">
        <Text size="m" color="green" bold>5 different attributes:</Text>
        <Text size="xs" bold className="mt10">
          1. clothes
          <br />
          2. eyes
          <br />
          3. hat
          <br />
          4. mouth
          <br />
          5. background
        </Text>
      </Col>
      <Col xs={24} sm={24} className="mt20 mb20">
        <Text>Peace Aliens NFT that have <b>8/8/8/8/8</b> rarities would be the rarest, and <b>1/1/1/1/1</b> would be the least rare/normal.</Text>
      </Col>
    </Row>
  </GreenBox>
);
