import styled from "styled-components";

import { media } from "../lib/helpers";

export default styled.div`
  color: ${props => props.theme.colors[props.color] || props.color || "white"} !important;
  font-family: ${props => props.theme.fontFamily};
  ${props => (props.bold && "font-weight: 800;")}
  ${props => (props.light && "font-weight: 300;")}
  ${props => (props.italic && "font-style: italic;")}
  ${props => (props.linethrough && "text-decoration: line-through;")}
  ${props => ({
    h2: `
      font-size: 50px;
      font-family: 'Cute Font', sans-serif;
      margin: 0 !important;
      margin-bottom: 0 !important;
    `,
    xxs: `
      font-size: 12px;
      line-height: 12px;
    `,
    xs: `
      font-size: 14px !important;
      line-height: 22px;
    `,
    s: `
      font-size: 16px !important;
      line-height: 22px;
    `,
    m: `
      font-size: 20px;
      line-height: 25px;
      font-weight: 400;
      font-family: 'Noto sans', sans-serif;
    `,
    l: `
      font-size: 25px;
      line-height: 30px;
      font-weight: 400;
    `,
     sub: `
      margin: auto;
      max-width: 600px;
      font-size: 22px;
      line-height: 32px;
      font-weight: 500;
      font-family: 'Noto sans', sans-serif;
      text-align: right;
    `,
  })[props.size || "xs"]};

  ${media.xs`
    ${props => ({
      sub: `
        font-size: 20px !important;
        line-height: 24px;
        text-align: center;
        margin-bottom: 15px;
      `,
    })[props.size || "xs"]}
  `}


`;
