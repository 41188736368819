import mapValues from "lodash.mapvalues";
import {Route} from "react-router-dom";
import {css} from "styled-components";

export const dimensionMap = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

export const media = mapValues(dimensionMap, value => (...args) => css`
  @media (max-width: ${value}) {
    ${css(...args)}
  }`);

export const RouteWithSubRoutes = (route: Object) => (
  <Route
    path={route.path}
    render={props => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);

export const fillField = {
  labelCol: {
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
  },
};

