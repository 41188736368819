import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import "./styles/helpers.css";
import "./styles/fonts.css";

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import FontFaceObserver from 'fontfaceobserver';


import App from './components/App';
import reportWebVitals from './reportWebVitals';
import defaultTheme from "./styles/defaultTheme";


//new FontFaceObserver("Cute font").load().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

//});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
