import React from 'react';
import styled, {keyframes} from "styled-components";
import {LoadingOutlined} from "@ant-design/icons";

export default ({
  className,
  htmlType,
  children,
  icon,
  loading,
  ...props
}: Object) => (
  <StyledButton2
    {...props}
    loading={loading}
    type={htmlType}
    className={className}
  >
    <div className="flex-h flex-center">
      {!!loading && (<div className="flex-v mr10"><LoadingOutlined /></div>)}
      {!!icon && (<div className="flex-v mr5">{icon}</div>)}
      <div>{children}</div>
    </div>
  </StyledButton2>
);

const glowing  = keyframes`
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
`;


const StyledButton2 = styled.button`
  display: inline-block;
  border: 0;
  font-style: normal;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 5px;
  &:hover {
    color: white;
  }

  &:active {
    opacity: 0.8;
  }

  ${props => props.width && `width: ${(props) => {
    if (String(props.width).indexOf("%") !== -1) {
      return props.width;
    }
    if (String(props.width).indexOf("px") !== -1) {
      return `${props.width}px !important;`;
    }
    return props.width;
  }}`}
  ${props => !!props.disabled && `
    opacity: 0.5;
  `}

  ${props => ({
    m: `
      font-size: 18px;
      line-height: 24px;
      padding: 10px 16px;
      border-radius: 20px;
    `,
    s: `
      font-size: 14px;
      line-height: 22px;
      padding: 4px 9px;
    `,
    xs: `
      font-size: 12px;
      line-height: 15px;
      padding: 5px 10px;
    `,

    default: `
      padding: 7px 20px;
      font-size: 16px;
      line-height: 24px !important;
    `,
  })[props.size || "default"]}

  ${props => `
    color: white;
    ${({
    primary: `
      background: #30318C;
      border-color: #30318C;
    `,
    success: `
      background: #009F4F;
      border-color: #009F4F;
    `,
    error: `
      background: #FF4D4F;
      border-color: #FF4D4F;
    `,
    warning: `
      background: #FFC700;
      border-color: #FFC700;
    `,
    gray: `
      background: #F5F5F5;
      border-color: white;
      color: #373737 !important;
    `,
    default: `
      background: white;
      color: #30318C !important;
    `,
  })[props.background || "default"]}`}

  ${props => ({
    primary: `
      border-color: #30318C;
      color: #30318C !important;
      background: transparent;
    `,
    success: `
      border-width: 1px;
      border-color: #009F4F;
      color: #009F4F !important;
      background: transparent;
    `,
    error: `
      border-color: #FF4D4F;
      color: #FF4D4F !important;
      background: transparent;
    `,
    warning: `
      border-color: #FFC700;
      color: #FFC700 !important;
      background: transparent;
    `,
    black: `
      border-color: #373737;
      color: #373737 !important;
      background: transparent;
    `,
    white: `
      background: transparent;
      border-color: white;
      color: white !important;
    `,
    default: `
      border: 1px solid #154d15 !important;
      color: white !important;
      background-image: ${props.theme.colors[props.token || "alien"].gradient};
      &:hover {
        box-shadow: rgba(167, 255, 38, 0.5) 0px 1px 20px;
        transition: all 0.1s;
      }
    `,
  })[props.border || "default"]}

    &.glow {
      width: 220px;
      height: 50px;
      border: none;
      outline: none;
      color: #333 !important;
      cursor: pointer;
      position: relative;
      z-index: 0;
      border-radius: 10px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #237923, #154d15);
      &:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -5;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: ${glowing} 20s linear infinite;
        opacity: 1;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
      }

      &.off {
        color: white !important;
        &:after {
          background: rgba(0, 0, 0, 1) !important;
        }


      }



      &:active {
        color: #000
      }

      &:active:before {
        //background: rgba(0, 0, 0, 0.5);
      }

      &:hover:before {
        opacity: 1;
      }

      &:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom,rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5));
        left: 0;
        top: 0;
        border-radius: 10px;
      }
    }
`;

