import {Row, Col} from "antd";
import styled from "styled-components";

import Text from "../components/Text";

const roads = [{
  stage: 25,
  title: "Welcome",
  content: `
    10 early supporters and contributors of Peace Aliens will be rewarded with Peace Aliens NFT airdrops. 
    The airdrops will be randomly distributed. 
    <br /><br />
    Thank you for your support for Peace Aliens to have some space in the metaverse. 
    Additional lottery of 0.3ETH jackpot will be rewarded to 3 lucky winners who take part in our journey.
  `,
}, {
  stage: 50,
  title: "Introduction",
  content: `
    We will use some of the funds for promotions to reach more people and introduce that Peace Aliens come in peace. 
    <br /><br />
    Additional lottery of 0.6ETH jackpot will be rewarded to 3 lucky winners who take part in our journey.
  `,
}, {
  stage: 75,
  title: "Community",
  content: `  
    At this stage Peace Aliens will feel a sense of acceptance, and would like to give back to the community. 
    Some of the funds and 25% of the royalties will be used to sweep the floor price and future marketing to spread more reach. 
    <br /><br />
    Additional lottery of 0.9ETH jackpot will be rewarded to 3 lucky winners who take part in our journey.
    <br /><br />  
    Potential development of season 2 collection to bring in more Peace Aliens to the metaverse.
  `,
}, {
  stage: 100,
  title: "Harmony",
  content: `
    At this stage Peace Aliens have found a place to live in harmony with the metaverse. 
    Peace Aliens feel a sense of stability and urge to expand collaboration with the community to develop further. 
    <br /><br />
    When we got into this stage, we become something that is worth to be further developed.
    We will continue further development of Peace Aliens, such as blockchain games, and possibly Peace Aliens merchandise. 
    <br /><br />
    Potential governance token will also be issued. 
    The tokens will be earn-able from staking and airdropps to Peace Aliens NFT holders. 
    The token will be the in-game currency of the blockchain games. 
    We will also take consensus to token holders to vote on what can be feasible to develop.
    <br /><br />
    Additional lottery of 1.2ETH jackpot will be rewarded to 3 lucky winners who take part in our journey. 
    <br /><br />
    We will also use some of the funds to keep the stability in the secondary market (OpenSea) and also to increase our reach. 
    Our goal at this stage would be to keep and make Peace Aliens NFT more valuable. 
  `,
}];

export default () => {
  return (
    <div>
      <Text as="h2" size="h2" color="green" className="text-center">ROADMAP</Text>

      {roads.map((road) => (
        <Row className="mb30" gutter="20">
          <Col xs={24} sm={4}>
            <Stage>
              <div className="stage">
                {road.stage}%
              </div>
            </Stage>
          </Col>
          <Col xs={24} sm={20}>
            <Text as="h3" size="m" bold color="green" className="mb5">{road.title}</Text>
            <Text size="s" dangerouslySetInnerHTML={{__html: road.content}} />
          </Col>
        </Row>
      ))}
      
    </div>

  );
}

const Stage = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  font-family: 'Cute Font',sans-serif;
  .stage {
    flex: 1;
    color: white;
    font-size: 50px;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    padding: 0px 0;
  }

  .sold {
    flex: 1;
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
  }
`;
