import {Layout} from "antd";
import styled from "styled-components";

import {media} from "../lib/helpers";

export const Content = styled.div`
  min-height: calc(100vh - ${props => props.theme.headerHeight});
`;

export const Wrapper = styled.div`
  font-family: 'Noto Sans', sans-serif !important;
  color: #222 !important;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  margin: auto;
  padding: 0 10px;
  ${media.xs`
    padding: 0 10px;
  `}
`;

export const Body = styled(Content)`
  font-family: 'Noto Sans', sans-serif !important;
  background: ${props => props.theme.background};
  ${media.xs`
    max-width: 100%;
  `}
`;

export const MainContent = styled(Layout)`
  background: ${props => props.theme.background};
  margin: 0;
  padding-top: ${props => props.theme.headerHeight};
  min-height: 100vh;
  padding: 20px 0;

  ${media.xs`
    padding: 15px;
  `}

`;

export const GreenBox = styled(Wrapper)`
  border-radius: 20px;
  padding: 0 0 0;
  background: #101f22;
  overflow: hidden;
`;
