import BasePage from "./pages/BasePage";
import HomePage from "./pages/HomePage";
import PreSalePage from "./pages/PreSalePage";
import FriendPage from "./pages/FriendPage";


const languagesStr = process.env.REACT_APP_AVAILABLE_LANGUAGES || "";

const toLanguageRoutes = (r) => {
  const routes = [];
  r.forEach((route) => {
    const languageRoute = {
      ...route,
      path: `/:lang(${languagesStr})${route.path}`,
      routes: route.routes && toLanguageRoutes(route.routes),
    };
    routes.push(languageRoute);
  });
  return routes;
};

const innerRoutes = [{
  path: "/",
  component: BasePage,
  exact: true,
  routes: [{
    path: "/",
    component: HomePage,
    exact: true,
  }],
}, {
  path: "/presale",
  exact: true,
  component: BasePage,
  routes: [{
    path: "/presale",
    component: PreSalePage,
    exact: true,
  }],
}, {
  path: "/pugfrens",
  exact: true,
  component: BasePage,
  routes: [{
    path: "/pugfrens",
    component: FriendPage,
    exact: true,
  }],
}];

const combinedRoutes = [
  ...innerRoutes,
  ...toLanguageRoutes(innerRoutes),
];

export default combinedRoutes;
