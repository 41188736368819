import Marquee from "react-fast-marquee";
import styled from "styled-components";
import randy from "randy";

import img1 from "../assets/0.png";
import img2 from "../assets/25.png";
import img3 from "../assets/38.png";
import img4 from "../assets/63.png";
import img5 from "../assets/74.png";
import img6 from "../assets/111.png";
import img7 from "../assets/112.png";
import img8 from "../assets/158.png";
import img9 from "../assets/206.png";
import img10 from "../assets/246.png";
import img11 from "../assets/540.png";
import img12 from "../assets/643.png";
import img13 from "../assets/663.png";
import img14 from "../assets/685.png";
import img15 from "../assets/695.png";
import img16 from "../assets/619.png";

import newimg114 from "../assets/new/114.png";
import newimg180 from "../assets/new/180.png";
import newimg309 from "../assets/new/309.png";
import newimg313 from "../assets/new/313.png";
import newimg332 from "../assets/new/332.png";
import newimg335 from "../assets/new/335.png";
import newimg346 from "../assets/new/346.png";
import newimg356 from "../assets/new/356.png";
import newimg359 from "../assets/new/359.png";
import newimg422 from "../assets/new/422.png";
import newimg454 from "../assets/new/454.png";
import newimg458 from "../assets/new/458.png";
import newimg554 from "../assets/new/554.png";
import newimg563 from "../assets/new/563.png";
import newimg579 from "../assets/new/579.png";
import newimg604 from "../assets/new/604.png";
import newimg628 from "../assets/new/628.png";
import newimg741 from "../assets/new/741.png";
import newimg807 from "../assets/new/807.png";
import newimg853 from "../assets/new/853.png";


const gfs = [
/*  {img: img1},
  {img: img2},
  {img: img3},
  {img: img4},
  {img: img5},
  {img: img6},
  {img: img7},
  {img: img8},
  {img: img9},
  {img: img10},
  {img: img11},
  {img: img12},
  {img: img13},
  {img: img14},
  {img: img15},
  {img: img16},*/
  {img: newimg114},
  {img: newimg180},
  {img: newimg309},
  {img: newimg313},
  {img: newimg332},
  {img: newimg335},
  {img: newimg346},
  {img: newimg356},
  {img: newimg359},
  {img: newimg422},
  {img: newimg454},
  {img: newimg458},
  {img: newimg554},
  {img: newimg563},
  {img: newimg579},
  {img: newimg604},
  {img: newimg628},
  {img: newimg741},
  {img: newimg807},
  {img: newimg853},
];

export default () => {
  const girlfriends = randy.shuffle(gfs);
  return (
    <Wrapper>
      <Marquee
        gradientColor={[16, 31, 34]}
      >
        {girlfriends.map((girlfriend) => (
          <Card>
            <img src={girlfriend.img} />
          </Card>
        ))}
      </Marquee>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 100%;
  margin: auto;
`;

const Card = styled.div`
  width: 180px;
  margin: 0;
  box-shadow: inset 0 5px 5px -50px white;
  img {
    width: 100%;
  }
`;
