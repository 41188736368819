import styled, {keyframes} from "styled-components";

import {media} from "../lib/helpers";

export default () => {
  return (
    <Wrapper className="">
      <Peace>PEACE</Peace>
      <Aliens>ALIENS</Aliens>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  line-height: 1;
  text-align: center !important;
  color: white;
  font-family: 'Cute Font', sans-serif;
  font-size: 80px;
  ${media.xs`
    font-size: 60px; 
  `}
`;

const Peace = styled.span`
  padding-left: 10px;
  letter-spacing: 1.5;
  font-weight: 300;
`;

const glow = keyframes`
  0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
    color: #fff;
    filter: blur(0.2px);
    text-shadow: 0 0 10px #48c248,
      0 0 20px #48c248,
      0 0 40px #48c248,
      0 0 80px #48c248,
      0 0 120px #48c248,
      0 0 200px #48c248,
      0 0 300px #48c248,
      0 0 400px #48c248;
  }
  20%, 24%, 55% {        
    color: #48c248;
    filter: blur(0px);
    text-shadow: none;
  }
`;

const Aliens = styled.span`
  font-weight: 700;
  width: 100%;
  color: #48c248;
  animation: ${glow} 5s ease-in-out infinite alternate;
`;
